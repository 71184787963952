import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

let ualabeeApiHost
let rechageAmountsApi

const configProd = {
  apiKey: 'AIzaSyB8oeagUQhJcVSyDUzgnFpmgSJ3t3ZGi7I',
  authDomain: 'miautobusv3.firebaseapp.com',
  databaseURL: 'https://miautobusv3.firebaseio.com',
  projectId: 'miautobusv3',
  storageBucket: 'miautobusv3.appspot.com',
  messagingSenderId: '361185485872',
  appId: '1:361185485872:web:f96e1a6a42120b497979f5',
  measurementId: 'G-TH18M1FN63'
}

const configDev = {
  apiKey: 'AIzaSyBISFa1AFeE2B-PYz_k3sVsrv9FnjSeEBw',
  authDomain: 'miautobus-development.firebaseapp.com',
  databaseURL: 'https://miautobus-development.firebaseio.com',
  projectId: 'miautobus-development',
  storageBucket: 'miautobus-development.appspot.com',
  messagingSenderId: '293591398387',
  appId: '1:293591398387:web:ab952a598001699d8fc486'
}

if (window.location.hostname !== 'marketplace.treep.app') {
  ualabeeApiHost = 'https://ualabee.dev/api/v2'
  rechageAmountsApi = {
    url: 'https://us-central1-miautobus-development.cloudfunctions.net/proxy',
    credential: 'Basic VWFsYWJlZV90ZXN0MTpjbGF2ZV90ZXN0XzEyMzQh',
    urlMovypay: 'https://devafiliados.movypay.com/montos'
  }
} else {
  ualabeeApiHost = 'https://ualabee.com/api/v2'
  rechageAmountsApi = {
    url: 'https://us-central1-miautobusv3.cloudfunctions.net/proxy',
    credential: 'Basic VWFsYWJlZV9Qcm9kMjAyMSo6OVFNUVdQNGtzVkRUYm5iWmFMMW4=',
    urlMovypay: 'https://afiliados.movypay.com/montos'
  }
}

if (!firebase.apps.length) {
  if (window.location.hostname !== 'marketplace.treep.app') {
    // dev code
    firebase.initializeApp(configDev)
  } else {
    // production code
    firebase.initializeApp(configProd)
  }
}

const analytics = firebase.analytics()
const db = firebase.firestore()

const logEvent = (eventName, params) => {
  analytics.logEvent(eventName, params)
}

export {
  analytics,
  logEvent,
  db,
  firebase,
  ualabeeApiHost,
  rechageAmountsApi
}
