import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentService } from '../../../../../actions/ui'
import { DialogContent, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useStyles } from './materialStyles'
import {
  BrandContainer,
  BrandLogo,
  BrandName,
  ButtonContainer,
  ButtonText,
  Content,
  ContentButtonContainer,
  ContentDescription,
  ContentDescriptionContainer,
  Description,
  DialogContentContainer,
  Discount,
  DiscountDetails,
  Name,
  OldPrice,
  Picture,
  PictureContainer,
  Price,
  ServicePrice,
  Title
} from '../../../../services/details/Details/styles'
import { useServices } from '../../../../../hooks/useServices'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import { Helmet } from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'
import squareIcon from '../../../../../img/square_icon.png'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'

export const Detail = () => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const service = useSelector(state => state?.ui?.current?.services?.service)

  const servicePrice = service?.discount_percentage_value && Number(service?.discount_percentage_value) !== 0 ? service?.price - ((service?.discount_percentage_value / 100) * service?.price) : service?.price
  const priceWithInterest = servicePrice + ((service?.interest_fees_value / 100) * servicePrice)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  const mode = useSelector(state => state?.ui?.mode)

  const {
    handleClickConsultService
  } = useServices()

  const handleClose = () => {
    dispatch(setCurrentService(undefined))

    if (cityConfig) {
      history.push(`/?city_id=${cityConfig.city_id}`)
    } else {
      history.push('/')
    }
  }

  return (
    <Dialog
      open={service}
      onClose={handleClose}
      aria-labelledby={service?.name}
      className={classes.modal}
    >
      <DialogActions>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <DialogContentText>
          <DialogContentContainer>
            <PictureContainer mode={mode}>
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                loop={service?.pictures?.length !== 1}
                style={{
                  width: '252px',
                  minWidth: '252px',
                  height: '80%',
                  minHeight: '252px',
                  borderRadius: 8
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false
                }}
                pagination={{
                  clickable: true
                }}
                className='mySwiper'
              >
                {service?.pictures?.map((picture, i) => {
                  return (
                    <SwiperSlide
                      key={i}
                    >
                      <img
                        width='252px'
                        height='252px'
                        src={picture?.picture_url}
                        style={{
                          borderRadius: '8px'
                        }}
                      />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </PictureContainer>
            <Content mode={mode}>
              <BrandContainer mode={mode}>
                {service?.group?.logo_url && <BrandLogo src={service?.group?.logo_url} />}
                {service?.group?.name && <BrandName>{service?.group?.name}</BrandName>}
              </BrandContainer>
              <Name>{service?.name}</Name>
              <Description style={{ marginBottom: 21 }}>{service?.description}</Description>
              <ServicePrice>
                {service?.discount_percentage_value && Number(service?.discount_percentage_value) !== 0
                  ? (
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#198F4C',
                      marginBottom: 5
                    }}
                    >
                      <Discount>-{service?.discount_percentage_value.toString()?.split('.')[0]}%</Discount>
                      <Price>${servicePrice?.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}</Price>
                      <OldPrice>${service?.price?.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}</OldPrice>
                    </div>
                    )
                  : (
                    <>
                      {servicePrice &&
                        <Price>
                          Desde
                          ${servicePrice?.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}
                        </Price>}
                    </>
                    )}
              </ServicePrice>

              {service?.amount_fees > 1 &&
                <DiscountDetails color={service?.interest_free_fees}>
                  {service?.amount_fees} cuotas de
                  ${(priceWithInterest / service?.amount_fees)?.toLocaleString(navigator.language, { maximumFractionDigits: 2 })} {service?.interest_free_fees && ' sin interés'}
                </DiscountDetails>}
              <ContentDescriptionContainer>
                <Title>Descripción</Title>
                <ContentDescription dangerouslySetInnerHTML={{ __html: service?.content }} />
              </ContentDescriptionContainer>
              <ButtonContainer>
                <Button
                  onClick={() => handleClickConsultService(service, 'listing')}
                  className={classes.buttonContent}
                  style={{ backgroundColor: service?.actions?.color }}
                >
                  <ContentButtonContainer>
                    <Picture
                      style={{ marginRight: 10 }} width='24px' height='24px'
                      src={service?.actions?.icon}
                    />
                    <ButtonText>
                      {service?.actions?.text}
                    </ButtonText>
                  </ContentButtonContainer>
                </Button>
              </ButtonContainer>
            </Content>
            <Helmet
              script={[
                helmetJsonLdProp({
                  '@context': 'https://schema.org/',
                  '@type': 'Product',
                  name: service?.name,
                  image: service?.icon_url,
                  slogan: service?.description,
                  offers: {
                    '@type': 'Offer',
                    priceCurrency: 'ARS',
                    price: servicePrice?.toLocaleString(navigator.language, { maximumFractionDigits: 2 })
                  },
                  description: service?.content
                })
              ]}
            >
              <title>{`Tienda Treep - ${service?.name?.replace('&', 'y')}`}</title>
              <meta name='description' content={service?.description} />
              <link href={squareIcon} rel='icon' />
              <link href={squareIcon} rel='apple-touch-icon' />
              <meta content={`Tienda Treep - ${service?.name?.replace('&', 'y')}`} property='og:title' />
              <meta content={service?.description} property='og:description' />
              <meta content='website' property='og:type' />
              <meta content='Tienda Treep' property='og:site_name' />
              <meta content='es_ES' property='og:locale' />
              <meta content='en_GB' property='og:locale:alternate' />
              <meta content='en_US' property='og:locale:alternate' />
              <meta
                content='Recarga de saldo, reserva de pasajes, seguro de pertenencias personales, movilidad y tecnología, tienda treep, tienda treep, tienda gualabe, tienda walabe, tienda ualabi, tienda ualaby, tienda walabi, tienda wallabi, tienda wallaby, tienda wallabee, tienda walaby, tienda walabee, tienda gualabi, tienda gualaby, tienda gualabee, tienda guallabi, tienda guallabee, tienda ualavee, tienda ualave, tienda gualave, tienda walave, tienda ualavi, tienda ualavy, tienda walavi, tienda wallavi, tienda wallavy, tienda wallavee, tienda walavy, tienda walavee, tienda gualavi, tienda gualavy, tienda gualavee, tienda guallavi, tienda guallavee'
                name='keywords'
              />
            </Helmet>
          </DialogContentContainer>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
